import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {store} from './redux/store'
import {Provider} from 'react-redux'

// import {PersistGate} from 'redux-persist/integration/react';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const root = ReactDOM.createRoot(document.getElementById('root'));

const query = new QueryClient()

root.render(
    <React.StrictMode>
        <QueryClientProvider client={query}>
            <Provider store={store}>
                {/*<PersistGate loading={null} persistor={persistor}>*/}
                    <App/>
                {/*</PersistGate>*/}
            </Provider>
        </QueryClientProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
