import React from "react";



const Hero = () => {
    return (
        <>
        
            <div className='mx-[4%]' >
                <p className='text-white mt-[10%] mb-[10%] text-5xl md:text-6xl font-bold'>
                       About <span className='text-GebetaMain'>GebetaMaps</span>{'\n'} 
                           
                </p>
              
            </div>

              
            </>
    )
}

export default Hero